export class WebhookData {

  webhook!: string;
  receivedAt!: string;
  headers!: Map<string, string[]>;
  body!: string;
  method!: string;

  constructor(obj: Partial<WebhookData>) {
    Object.assign(this, obj);

    this.headers = new Map<string, string[]>();
    for (const key in obj.headers) {
      this.headers.set(key, (obj.headers as any)[key]);
    }
  }
}
