import React from 'react';
import { TableContainer, TableHead, Table, TableCell, TableBody, TableRow } from '@material-ui/core';
import Axios from 'axios';
import { WebhookData } from '../model/WebhookData';
import WebhookDetails from './WebhookDetails';
import { Column } from '../utils/Column';

interface DashboardListProps {
  webhookNames: string[],
}

interface DashboardListState {
  datas: WebhookData[],
}

class DashboardList extends React.Component<DashboardListProps, DashboardListState> {

  constructor(props: DashboardListProps) {
    super(props);
    this.state = {
      datas: [],
    };
  }

  componentDidUpdate(prevProps: DashboardListProps, prevState: DashboardListState) {
    if (this.props.webhookNames !== prevProps.webhookNames) {
      this.retreiveDatas();
    }
  }

  retreiveDatas() {
    let promises: Promise<WebhookData[]>[] = [];

    console.log(this.props.webhookNames);

    for (let name of this.props.webhookNames) {
      promises = promises.concat([this.getDataFor(name)]);
    }

    Promise.all(promises)
      .then(res => {
        if (res.length > 0) {
          const list = res.reduce((acc, el) => acc.concat(el));
          list.sort((a, b) => a.receivedAt.localeCompare(b.receivedAt));
          this.setState({
            datas: list,
          });
        } else {
          this.setState({
            datas: []
          });
        }
      });
  }

  async getDataFor(name: string): Promise<WebhookData[]> {
    const { data } = await Axios.get<WebhookData[]>(`/webhook/${name}`);
    return data.map(d => {
      d.webhook = name;
      return new WebhookData(d);
    });
  }

  render() {
    return (
      // maxHeight is necessary for stickyHeader
      <TableContainer style={{maxHeight: '94vh'}}>
        <Table stickyHeader size="small">
          <TableHead>
            <TableRow key="header">
              {DASHBOARD_LIST_COLUMNS.map(col => {
                return (
                  <TableCell
                    key={col.id + "-header"}
                    align="left"
                    style={{minWidth: `${col.minWidth}rem`}}>
                  {col.label}
                </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {this.state.datas.map(data => {
              return (
                <WebhookDetails key={data.receivedAt + '-' + data.webhook} data={data} />
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    );
  }
}

export const DASHBOARD_LIST_COLUMNS: Column[] = [
  { id: 'collapse', label: '', minWidth: 2, format: (data: WebhookData) => '' },
  { id: 'receivedAt', label: 'Received At', minWidth: 10, format: (data: WebhookData) => formatDate(data.receivedAt) },
  { id: 'webhook', label: 'Webhook', minWidth: 10, format: (data: WebhookData) => data.webhook, },
  // { id: 'headers', label: 'Headers', minWidth: 15, format: (data: WebhookData) => reduceString(JSON.stringify(data.headers)) },
  { id: 'method', label: 'Method', minWidth: 10, format: (data: WebhookData) => reduceString(data.method, 100), },
  { id: 'body', label: 'Body', minWidth: 40, format: (data: WebhookData) => reduceString(data.body, 100), },
];

function reduceString(value: string, length: number) {
  return value.length > length ? value.substr(0, length - 3) + "..." : value;
}

function formatDate(rawDate: string) {
  try {
    const date = new Date(rawDate);
    return `${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`
      + ` - ${date.getDate()}/${date.getMonth()}/${date.getFullYear()}`;
  } catch {}
  return rawDate;
}

export default DashboardList;
